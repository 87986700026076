import { Fragment } from 'react';
import { useProxy } from 'valtio/utils';
import { AppLink2 } from '~/components/AppLink2';
import { DataList } from '~/components/DataList';
import { DayjsDisplay } from '~/components/DayjsDisplay';
import { NumberDisplay } from '~/components/NumberDisplay';
import { MantinePagination } from '~/modules/FrLogic/v1/logics/Pagination/MantinePagination';
import { BooleanDisplay } from '~/pages/strategy_center_admin/_components/BooleanDisplay';
import { InstrumentDisplay } from '~/pages/strategy_center_admin/_components/InstrumentDisplay';
import { pageConfig } from '~/pages/strategy_center_admin/pageConfig';
import { pageStore } from '~/pages/strategy_center_admin/pageStore';
import { StrategyOverviewEditFormModalButton } from '~/pages/strategy_center_admin/strategy-overview/_components/StrategyOverviewEditFormModalButton';
import { component } from '~/utils/component';
export const StrategyOverviewDataGrid = component(() => {
    const state = useProxy(pageStore.strategyOverviewStore);
    const SorterButton = pageStore.strategyOverviewStore.sorter.Icon;
    return (<Fragment>
      <DataList data={state.overviews} columns={[
            {
                width: 32,
                renderHeaderCell: () => '編輯',
                renderCell: datum => (<StrategyOverviewEditFormModalButton valueOfStrategyOverview={datum.value}/>),
            },
            {
                width: 72,
                renderHeaderCell: () => 'ID',
                renderCell: datum => (<AppLink2 href={pageConfig.Href.strategyStatements.replace(/{id}/gi, String(datum.value.id))}>
                <Fragment>{datum.value.id}</Fragment>
              </AppLink2>),
            },
            {
                width: 148,
                renderHeaderCell: () => '策略名稱',
                renderCell: datum => (<AppLink2 href={pageConfig.Href.strategyStatements.replace(/{id}/gi, String(datum.value.id))}>
                <Fragment>{datum.value.name}</Fragment>
              </AppLink2>),
            },
            {
                width: 148,
                renderHeaderCell: () => '顯示名稱',
                renderCell: datum => (<AppLink2 href={pageConfig.Href.strategyStatements.replace(/{id}/gi, String(datum.value.id))}>
                <Fragment>{datum.value.displayName}</Fragment>
              </AppLink2>),
            },
            {
                width: 80,
                renderHeaderCell: () => '策略師',
                renderCell: datum => {
                    return <Fragment>{datum.value.userAccount}</Fragment>;
                },
            },
            {
                width: 88,
                renderHeaderCell: () => '策略商品',
                renderCell: datum => <InstrumentDisplay value={datum.value.symbolRoot}/>,
            },
            {
                width: 148,
                renderHeaderCell: () => '交易商品',
                renderCell: datum => <Fragment>{datum.value.positionSymbol}</Fragment>,
            },
            {
                width: 64,
                renderHeaderCell: () => <SorterButton by='previousPosition'>前次部位</SorterButton>,
                renderCell: datum => <Fragment>{datum.value.previousPosition}</Fragment>,
            },
            {
                width: 64,
                renderHeaderCell: () => <SorterButton by='currentPosition'>目前部位</SorterButton>,
                renderCell: datum => <Fragment>{datum.value.currentPosition}</Fragment>,
            },
            {
                width: 80,
                renderHeaderCell: () => '訊號價格',
                renderCell: datum => <Fragment>{datum.value.price}</Fragment>,
            },
            {
                width: 172,
                renderHeaderCell: () => <SorterButton by='datetime'>觸發時間</SorterButton>,
                renderCell: datum => (<DayjsDisplay value={datum.value.datetime} format='dateTimeSeconds' nilable/>),
            },
            {
                width: 116,
                renderHeaderCell: () => <SorterButton by='returnOnInvestment'>損益(%)</SorterButton>,
                renderCell: datum => (<NumberDisplay value={datum.value.returnOnInvestment} showColored showAsPercentage thousandCommaSymbol numberToFixed={2}/>),
            },
            {
                width: 116,
                renderHeaderCell: () => <SorterButton by='totalPnl'>損益</SorterButton>,
                renderCell: datum => (<NumberDisplay value={datum.value.totalPnl} showColored thousandCommaSymbol/>),
            },
            {
                width: 116,
                renderHeaderCell: () => <SorterButton by='maxTotalProfit'>最大累積獲利</SorterButton>,
                renderCell: datum => (<NumberDisplay value={datum.value.maxTotalProfit} showColored thousandCommaSymbol/>),
            },
            {
                width: 116,
                renderHeaderCell: () => <SorterButton by='maxDrawdown'>最大回檔</SorterButton>,
                renderCell: datum => (<NumberDisplay value={datum.value.maxDrawdown} showColored thousandCommaSymbol/>),
            },
            {
                width: 64,
                renderHeaderCell: () => <SorterButton by='winRate'>區間勝率</SorterButton>,
                renderCell: datum => (<NumberDisplay value={datum.value.winRate} showAsPercentage numberToFixed={1}/>),
            },
            {
                width: 32,
                renderHeaderCell: () => '推薦',
                renderCell: datum => <BooleanDisplay value={datum.value.recommended}/>,
            },
        ]}/>
      {/* <FrDataGrid.DataGrid
          ref={gridRef}
          rowData={state.overviews}
          defaultColDef={{
            flex: 1,
            resizable: true,
          }}
          // onRowSelected={onRowSelected}
          columnDefs={[
            {
              headerName: '策略名稱',
              field: expectType<FieldKey>('name'),
              valueGetter: params => params.data,
              cellRenderer2: params => {
                const value = params.value as Overview
  
                return (
                  <AppLink2
                    href={pageConfig.Href.strategyStatements.replace(/{id}/gi, String(value.id))}
                  >
                    <Fragment>{value.name}</Fragment>
                  </AppLink2>
                )
              },
            },
            {
              headerName: '策略師',
              field: expectType<FieldKey>('userAccount'),
              cellRenderer2: params => {
                return <Fragment>{params.value}</Fragment>
              },
            },
            {
              headerName: '策略商品',
              field: expectType<FieldKey>('symbolRoot'),
              cellRenderer2: InstrumentDisplay,
            },
            {
              headerName: '交易商品',
              field: expectType<FieldKey>('positionSymbol'),
              cellRenderer2: params => {
                return <Fragment>{params.value}</Fragment>
              },
            },
            {
              headerName: '前次部位',
              field: expectType<FieldKey>('previousPosition'),
              headerComponent2: params => {
                return <SorterButton by='PreviousPosition'>{params.displayName}</SorterButton>
              },
              cellRenderer2: params => {
                return <Fragment>{params.value}</Fragment>
              },
            },
            {
              headerName: '目前部位',
              field: expectType<FieldKey>('currentPosition'),
              headerComponent2: params => {
                return <SorterButton by='CurrentPosition'>{params.displayName}</SorterButton>
              },
              cellRenderer2: params => {
                return <Fragment>{params.value}</Fragment>
              },
            },
            {
              headerName: '訊號價格',
              field: expectType<FieldKey>('price'),
              cellRenderer2: params => {
                return <Fragment>{params.value}</Fragment>
              },
            },
            {
              headerName: '觸發時間',
              field: expectType<FieldKey>('datetime'),
              minWidth: 160,
              headerComponent2: params => {
                return <SorterButton by='Datetime'>{params.displayName}</SorterButton>
              },
              cellRenderer2: params => {
                return (
                  <DayjsDisplay
                    value={params.value}
                    format='dateTimeSeconds'
                    nilable
                  />
                )
              },
            },
  
            {
              headerName: '區間損益',
              minWidth: 120,
              field: expectType<FieldKey>('totalPnl'),
              headerComponent2: params => {
                return <SorterButton by='TotalPnl'>{params.displayName}</SorterButton>
              },
              cellRenderer2: params => {
                return (
                  <NumberDisplay
                    value={params.value}
                    showColored
                  />
                )
              },
            },
            {
              headerName: '區間勝率',
              minWidth: 120,
              headerComponent2: params => {
                return <SorterButton by='WinRate'>{params.displayName}</SorterButton>
              },
              field: expectType<FieldKey>('winRate'),
              cellRenderer2: params => {
                return (
                  <NumberDisplay
                    value={params.value}
                    showColored
                    showPercentSymbol
                    numberToFixed={1}
                  />
                )
              },
            },
            {
              headerName: '區間最大虧損',
              minWidth: 160,
              headerComponent2: params => {
                return <SorterButton by='MaxTotalLoss'>{params.displayName}</SorterButton>
              },
              field: expectType<FieldKey>('maxTotalLoss'),
              cellRenderer2: params => {
                return (
                  <NumberDisplay
                    value={params.value}
                    showColored
                  />
                )
              },
            },
            {
              headerName: '區間最大獲利',
              minWidth: 160,
              field: expectType<FieldKey>('maxTotalProfit'),
              headerComponent2: params => {
                return <SorterButton by='MaxTotalProfit'>{params.displayName}</SorterButton>
              },
              cellRenderer2: params => {
                return (
                  <NumberDisplay
                    value={params.value}
                    showColored
                  />
                )
              },
            },
            {
              headerName: '啟用交易',
              field: expectType<FieldKey>('enableTrading'),
              cellRenderer2: BooleanDisplay,
            },
            {
              headerName: '啟用訂閱',
              field: expectType<FieldKey>('enableSubscription'),
              cellRenderer2: BooleanDisplay,
            },
            {
              headerName: '啟用上傳',
              field: expectType<FieldKey>('enableUpload'),
              cellRenderer2: BooleanDisplay,
            },
            {
              headerName: '編輯',
              field: expectType<FieldKey>('id'),
              valueGetter: params => params.data as Overview,
              cellRenderer2: params => {
                const datum = params.value as Overview
  
                return (
                  <Button
                    size='xs'
                    onClick={event => {
                      pageStore.modalStore.showModalComponent(() => (
                        <StrategyOverviewEditForm
                          initialValues={datum}
                          onCancel={pageStore.modalStore.closeModal}
                          onDelete={() => {
                            apirc.strategyDev
                              .http('delete', '/api/strategies/{strategyId}')
                              .request({ strategyId: datum.id })
                              .then(pageStore.strategyOverviewStore.loadData)
                              .then(pageStore.modalStore.closeModal)
                          }}
                          onUpdate={updateDto => {
                            apirc.strategyDev
                              .http('patch', '/api/strategies/{strategyId}')
                              .request({ strategyId: datum.id, ...updateDto })
                              .then(pageStore.strategyOverviewStore.loadData)
                              .then(pageStore.modalStore.closeModal)
                          }}
                        />
                      ))
                    }}
                  >
                    編輯
                  </Button>
                )
              },
            },
          ]}
        /> */}
      <MantinePagination {...state.pagination}/>
    </Fragment>);
});
